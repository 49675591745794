import logoLink from '../resources/momentum.svg';

export const cssVariables = {
    active: '#646BF3',
    inactive: '#CCC',

    primary_light: '#D8DAFC',
    primary: '#646BF3',
    primary_dark: '#5c63e0',
    text_on_primary: '#fff',
    primary_transparent: '#dbdbdb',

    secondary: '#211A1D',
    secondary_light: '#eceded',
    text_on_secondary: 'white',
    secondary_transparent: '#F7F7F7',

    font_color: 'black',
    link_color: '#84bd3f',

    font: 'Arial, Helvetica, sans-serif',

    border_width: '1px',
    border_style: 'solid',

    edit_color: '#646BF3',
    activate_color: '#A6A6A6',
    deactivate_color: '#A6A6A6',
    delete_color: '#211A1D',

    dropdown_background: '#D8DAFC',

    toggle_switch_line: 'white',
};

export const logo = logoLink;
